import React from 'react'

import Layout from '../components/Layout'
const ThankYouPage = () => (
  <Layout>
    <div>
      <h1>Merci de nous contacter</h1>
      <p>Nous essayons de repondre au plus vite</p>
    </div>
  </Layout>
)

export default ThankYouPage
